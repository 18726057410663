/* eslint-disable @nx/enforce-module-boundaries */
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { aditionalHeadersModel, FiltersModel } from '@kanzi-apes/kanzi-models';
import { ProgressBarService } from '@kanzi-apes/kanzi-ui';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { KanziControlErrorsService } from '../control-errors/kanzi-control-errors.service';
import { KanziUtilService } from '../utils/kanzi-util.service';

/**
 * Interface for the download event model
 */
export interface HttpDownloadEventModel {
  percent?: number | undefined;
  EventType: HttpEventType;
  response?: any;
}

@Injectable({
  providedIn: 'root',
})
export class KanziRestClientService {
  /**
   * Constructor of the class
   * @param http HttpClient service
   * @param errorService Error service
   * @param utilService Util service
   * @param progressBarService Progress bar service
   */
  constructor(
    private http: HttpClient,
    private errorService: KanziControlErrorsService,
    private utilService: KanziUtilService,
    private progressBarService: ProgressBarService
  ) {}

  /**
   * Method to make a GET request
   * @param url {string} URL to make the request
   * @param filters {FiltersModel} Filters to apply to the request
   * @param errorMsg {string} Error message to show in case of error
   * @param additionalHeaders {aditionalHeadersModel} Additional headers to send in the request
   * @returns {Observable<T>} Observable with the response of the request
   */
  get<T>(
    url: string,
    filters?: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel,
    tokenData?: { tokenName?: string; tokenPrefix?: string }
  ): Observable<T> {
    const options = {
      headers: this.utilService.getHeaders(
        additionalHeaders,
        tokenData?.tokenName,
        tokenData?.tokenPrefix
      ),
    };
    let params = new HttpParams();
    if (filters) {
      const keys: any[] = Object.keys(filters);
      for (let i = 0; i < keys.length; i++) {
        params = params.set(`${keys[i]}`, filters[keys[i]]);
      }
    }
    return this.http
      .get<T>(url, { ...options, params: params })
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  /**
   * Method to get reports
   * @param url {string} URL to make the request
   * @param filters {FiltersModel} Filters to apply to the request
   * @param errorMsg {string} Error message to show in case of error
   * @param additionalHeaders {aditionalHeadersModel} Additional headers to send in the request
   * @returns {Observable<T>} Observable with the response of the request
   */
  getReports<T>(
    url: string,
    filters?: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    const options = { headers: this.utilService.getHeaders(additionalHeaders) };
    if (filters) {
      url = url + `?`;
      const keys: any[] = Object.keys(filters);
      for (let i = 0; i < keys.length; i++) {
        if (i === 0) {
          url = url + `${keys[i]}=${filters[keys[i]]}`;
        } else {
          url = url + `&${keys[i]}=${filters[keys[i]]}`;
        }
      }
    }
    return this.http
      .get<T>(url, options)
      .pipe(catchError((_) => throwError(() => errorMsg)));
  }

  /**
   * Method to make a POST request
   * @param url {string} URL to make the request
   * @param data {any} Data to send in the request
   * @param errorMsg {string} Error message to show in case of error
   * @param additionalHeaders {aditionalHeadersModel} Additional headers to send in the request
   * @returns {Observable<T>} Observable with the response of the request
   */
  post<T>(
    url: string,
    data: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel,
    tokenData?: { tokenName?: string; tokenPrefix?: string }
  ): Observable<T> {
    const options = {
      headers: this.utilService.getHeaders(
        additionalHeaders,
        tokenData?.tokenName,
        tokenData?.tokenPrefix
      ),
    };
    const body = JSON.stringify(data);
    return this.http
      .post<T>(url, body, options)
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  /**
   * Method to make a request
   * @param method {string} {GET, POST, PUT, DELETE, PATCH, etc}
   * @param url {string} URL to make the request
   * @param data {any} Data to send in the request
   * @param filters {FiltersModel} Filters to apply to the request
   * @param errorMsg {string} Error message to show in case of error
   * @param responseType {string} Type of response
   * @param additionalHeaders {aditionalHeadersModel} Additional headers to send in the request
   * @returns {Observable<T | HttpEvent<T>>} Observable with the response of the request
   */
  request<T>(
    method: string,
    url: string,
    data?: any,
    filters?: any,
    errorMsg?: string,
    responseType?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T | HttpEvent<T>> {
    let options: { headers?: any; body?: any; responseType?: any } = {
      headers: this.utilService.getHeaders(additionalHeaders),
    };
    let body = null;
    if (data) {
      let body = JSON.stringify(data);
      options = { ...options, body };
    }
    if (filters) {
      url = url + `?`;
      const keys: any[] = Object.keys(filters);
      for (let i = 0; i < keys.length; i++) {
        if (i === 0) {
          url = url + `${keys[i]}=${filters[keys[i]]}`;
        } else {
          url = url + `&${keys[i]}=${filters[keys[i]]}`;
        }
      }
    }
    if (responseType) {
      options = { ...options, responseType };
    }

    return this.http
      .request<T>(method, url, { ...options, observe: 'events' })
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  /**
   * Method to make a PUT request
   * @param url {string} URL to make the request
   * @param data {any} Data to send in the request
   * @param errorMsg {string} Error message to show in case of error
   * @param additionalHeaders {aditionalHeadersModel} Additional headers to send in the request
   * @returns {Observable<T>} Observable with the response of the request
   */
  put<T>(
    url: string,
    data: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    const options = { headers: this.utilService.getHeaders(additionalHeaders) };
    const body = JSON.stringify(data);
    return this.http
      .put<T>(url, body, options)
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  /**
   * Method to make a PATCH request
   * @param url {string} URL to make the request
   * @param data {any} Data to send in the request
   * @param filters {FiltersModel} Filters to apply to the request
   * @param errorMsg {string} Error message to show in case of error
   * @param additionalHeaders {aditionalHeadersModel} Additional headers to send in the request
   * @returns {Observable<T>} Observable with the response of the request
   */
  patch<T>(
    url: string,
    data: any,
    filters?: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel,
    tokenData?: { tokenName?: string; tokenPrefix?: string }
  ): Observable<T> {
    const options = {
      headers: this.utilService.getHeaders(
        additionalHeaders,
        tokenData?.tokenName,
        tokenData?.tokenPrefix
      ),
    };
    if (filters) {
      
      const keys = Object.keys(filters);
      for (let i = 0; i < keys.length; i++) {
        if (i === 0) {
          url = url + `?${keys[i]}=${filters[keys[i]]}`;
        } else {
          url = url + `&${keys[i]}=${filters[keys[i]]}`;
        }
      }
    }
    const body = JSON.stringify(data);
    return this.http
      .patch<T>(url, body, options)
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  /**
   * Method to make a DELETE request
   * @param url {string} URL to make the request
   * @param errorMsg {string} Error message to show in case of error
   * @param additionalHeaders {aditionalHeadersModel} Additional headers to send in the request
   * @returns {Observable<T>} Observable with the response of the request
   */
  delete<T>(
    url: string,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    const options = { headers: this.utilService.getHeaders(additionalHeaders) };
    return this.http
      .delete<T>(url, options)
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  /**
   * Method to make a DELETE request without control
   * @param url {string} URL to make the request
   * @param errorMsg {string} Error message to show in case of error
   * @param additionalHeaders {aditionalHeadersModel} Additional headers to send in the request
   * @returns {Observable<T>} Observable with the response of the request
   */
  deleteWithoutControl<T>(
    url: string,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    const options = { headers: this.utilService.getHeaders(additionalHeaders) };
    return this.http.delete<T>(url, options);
  }

  /**
   * Method to get the status of a request
   * @param url {string} URL to make the request
   * @param additionalHeaders {aditionalHeadersModel} Additional headers to send in the request
   * @returns {Observable<number>} Observable with the status of the request
   */
  status(
    url: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<number> {
    return this.http
      .get(url, {
        headers: this.utilService.getHeaders(additionalHeaders),
        observe: 'response',
      })
      .pipe(
        map((response) => response.status),
        catchError((response) => of(response.status))
      );
  }

  /**
   * Method to make a POST request with form data
   * @param url {string} URL to make the request
   * @param data {any} Data to send in the request
   * @param errorMsg {string} Error message to show in case of error
   * @param additionalHeaders {aditionalHeadersModel} Additional headers to send in the request
   * @returns {Observable<T>} Observable with the response of the request
   */
  postFormData<T>(
    url: string,
    data: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    const options = {
      headers: this.utilService.getHeadersUploadFile(additionalHeaders),
    };
    return this.http
      .post<T>(url, data, options)
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  /**
   * Method to make a PUT request with form data
   * @param url {string} URL to make the request
   * @param data {any} Data to send in the request
   * @param filters {FiltersModel} Filters to apply to the request
   * @param errorMsg {string} Error message to show in case of error
   * @param additionalHeaders {aditionalHeadersModel} Additional headers to send in the request
   * @returns {Observable<T>} Observable with the response of the request
   */
  patchFormData<T>(
    url: string,
    data: any,
    filters?: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    const options = {
      headers: this.utilService.getHeadersUploadFile(additionalHeaders),
    };
    if (filters) {
      url = url + `?`;
      const keys = Object.keys(filters);
      for (let i = 0; i < keys.length; i++) {
        if (i === 0) {
          url = url + `${keys[i]}=${filters[keys[i]]}`;
        } else {
          url = url + `&${keys[i]}=${filters[keys[i]]}`;
        }
      }
    }
    return this.http
      .patch<T>(url, data, options)
      .pipe(catchError(this.errorService.handleError<T>(errorMsg)));
  }

  /**
   * Method to make a request with form data
   * @param url {string} URL to make the request
   * @param packet {string} Packet to control the progress bar
   * @param filters {FiltersModel} Filters to apply to the request
   * @param errorMsg {string} Error message to show in case of error
   * @param additionalHeaders {aditionalHeadersModel} Additional headers to send in the request
   * @returns {Observable<T>} Observable with the response of the request
   */
  downLoadData<T>(
    url: string,
    packet: string,
    filters?: any,
    errorMsg?: string,
    additionalHeaders?: aditionalHeadersModel
  ): Observable<T> {
    if (filters) {
      url = url + `?`;
      const keys: any[] = Object.keys(filters);
      for (let i = 0; i < keys.length; i++) {
        if (i === 0) {
          url = url + `${keys[i]}=${filters[keys[i]]}`;
        } else {
          url = url + `&${keys[i]}=${filters[keys[i]]}`;
        }
      }
    }

    const request = new HttpRequest('GET', url, null, {
      headers: this.utilService.getHeaders(additionalHeaders),
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request<T>(request).pipe(
      map((event) => this.getPercent(event)),
      tap((event) => this.progressBarControl(event, packet)),
      map((event) => this.eventResponse(event)),
      last(),
      catchError(this.errorService.handleError<T>(errorMsg))
    );
  }

  /**
   * Method to make a request with form data
   * @param event {HttpEvent<any>} Event of the request
   * @returns {HttpDownloadEventModel} Event model
   */
  getPercent(event: HttpEvent<any>): HttpDownloadEventModel {
    switch (event.type) {
      case HttpEventType.DownloadProgress: {
        const percentDone = event.total
          ? Math.round((100 * event.loaded) / event.total)
          : 0;
        return {
          percent: percentDone,
          EventType: event.type,
        };
      }

      case HttpEventType.Response: {
        return { response: event.body, EventType: event.type };
      }

      default: {
        return { EventType: event.type };
      }
    }
  }

  /**
   * Method to control the progress bar
   * @param event {HttpDownloadEventModel} Event model
   * @param packet {string} Packet to control the progress bar
   */
  progressBarControl(event: HttpDownloadEventModel, packet: string) {
    event.EventType === HttpEventType.DownloadProgress
      ? this.progressBarService.onProgress(event.percent ?? 0, packet)
      : event.EventType === HttpEventType.Response
      ? this.progressBarService.onComplete(packet)
      : console.log('OTRO EVENTO', event);
  }

  /**
   * Method to get the response of the request
   * @param event {HttpDownloadEventModel} Event model
   * @returns {any} Response of the request
   */
  eventResponse(event: HttpDownloadEventModel): any {
    return event.EventType === HttpEventType.Response ? event.response : event;
  }
}
