<div
  class="layout-sidebar"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <div class="layout-sidebar-top">
    <a href="/" class="layout-sidebar-logo">
      <img
        [routerLink]="['/']"
        [src]="'assets/images/logos/logo-apes-' + logo + '.png'"
        alt="Kanzi-Logo"
      />
      <span class="text-2xl font-bold text-color ml-2">Kanzi</span>
    </a>
    <a href="/" class="layout-sidebar-logo-slim"
      ><img
        [routerLink]="['/']"
        [src]="'assets/images/logos/logo-apes-' + logo + '.png'"
        alt="Kanzi-Logo"
    /></a>
    <button
      class="layout-sidebar-anchor p-link"
      type="button"
      (click)="anchor()"
    ></button>
  </div>
  <kanzi-ui-menu-user-profile
    #menuProfileStart
    *ngIf="menuProfilePosition === 'start'"
    [userProfile]="userProfile()"
    [userProfileRfid]="userProfileRfid()"
    [userConfigsWMS]="userConfigsWMS()"
    (onLogout)="logout()"
  ></kanzi-ui-menu-user-profile>
  <div #menuContainer class="layout-menu-container">
    <kanzi-ui-main-menu [menuOptions]="mainMenuItems()"></kanzi-ui-main-menu>
  </div>
  <kanzi-ui-menu-user-profile
    #menuProfileStart
    *ngIf="menuProfilePosition === 'end'"
    [userProfileRfid]="userProfileRfid()"
    [userProfile]="userProfile()"
    (onLogout)="logout()"
  ></kanzi-ui-menu-user-profile>
</div>
