import { Action } from '@ngrx/store';
import { Authenticate, UserModel, Token } from '@kanzi-apes/kanzi-models';

export enum AuthActionTypes {
  Login = '[Auth] Login',
  Logout = '[Auth] Logout',
  LoginSuccess = '[Auth] Login Success',
  LoginSuccessMtt = '[Auth] Login Success by mtt',
  LoginFailure = '[Auth] Login Failure',
  LoginRedirect = '[Auth] Login Redirect',
  UserProfileLoad = '[Auth] User Profile Load',
  UserProfileSuccess = '[Auth] User Profile Success Load',
  UserLoginFailureToken = '[Auth] No token',
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;

  constructor(public payload: Authenticate) {}
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;

  constructor(public payload: { token: Token }) {}
}

export class LoginSuccessMtt implements Action {
  readonly type = AuthActionTypes.LoginSuccessMtt;

  constructor(
    public payload: { token: Token | null; user: UserModel | null }
  ) {}
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;

  constructor(public payload: any) {}
}

export class LoginRedirect implements Action {
  readonly type = AuthActionTypes.LoginRedirect;
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
  constructor() {}
}

export class UserProfileLoad implements Action {
  readonly type = AuthActionTypes.UserProfileLoad;
  constructor() {}
}

export class UserProfileSuccess implements Action {
  readonly type = AuthActionTypes.UserProfileSuccess;
  constructor(public payload: { user: UserModel }) {}
}

export class UserLoginFailureToken implements Action {
  readonly type = AuthActionTypes.UserLoginFailureToken;
  constructor() {}
}

export type AuthActions =
  | Login
  | LoginSuccess
  | LoginFailure
  | LoginRedirect
  | Logout
  | UserProfileLoad
  | UserProfileSuccess
  | UserLoginFailureToken
  | LoginSuccessMtt;
