<div class="layout-menu-profile">
  <button
    class="p-link border-noround"
    (click)="toggleMenu()"
    pTooltip="Profile"
    [tooltipDisabled]="isTooltipDisabled"
  >
    <img
      src="assets/demo/images/avatar/amyelsner.png"
      alt="avatar"
      style="width: 32px; height: 32px"
    />
    <span>
      <strong>{{
        userProfile()?.Username || userProfileRfid()?.username 
      }}</strong>
      @if (userConfigsWMS()) { @for (role of userConfigsWMS()?.roles; track
      $index) {
      <small>{{ role.name }}</small>
      } } @if(userProfileRfid()){
      <small>{{ userProfileRfid()?.configs?.role?.name }}</small>

      }
    </span>
    <i
      class="layout-menu-profile-toggler pi pi-fw"
      [ngClass]="{
        'pi-angle-down': menuProfilePosition === 'start' || isHorizontal,
        'pi-angle-up': menuProfilePosition === 'end' && !isHorizontal
      }"
    ></i>
  </button>
  <ul *ngIf="menuProfileActive" [@menu]="isHorizontal ? 'overlay' : 'inline'">
    <li pTooltip="Profile" [tooltipDisabled]="isTooltipDisabled">
      <button class="p-link" [routerLink]="['users/profile']">
        <i class="pi pi-file-o pi-fw"></i>
        <span>Profile</span>
      </button>
    </li>
    <li pTooltip="Support" [tooltipDisabled]="isTooltipDisabled">
      <button class="p-link">
        <i class="pi pi-compass pi-fw"></i>
        <span>Soporte</span>
      </button>
    </li>
    <li pTooltip="Logout" [tooltipDisabled]="isTooltipDisabled">
      <button class="p-link" (click)="logout()">
        <i class="pi pi-power-off pi-fw"></i>
        <span>Logout</span>
      </button>
    </li>
  </ul>
</div>
