import {
  Component,
  OnInit,
  input,
  TemplateRef,
  AfterContentInit,
  ContentChildren,
  QueryList,
  ContentChild,
  Output,
  EventEmitter,
  effect,
  OnDestroy,
} from '@angular/core';

import { KanziTemplate } from '../../directives/kanzi-template/kanzi-template.directive';

@Component({
  selector: 'kanzi-ui-detail-sidebar',
  templateUrl: './kanzi-ui-detail-sidebar.component.html',
  styleUrls: ['./kanzi-ui-detail-sidebar.component.scss'],
})
export class KanziUiDetailSidebarComponent
  implements OnInit, AfterContentInit, OnDestroy
{
  title = input.required<string>();
  description = input<string>();
  showSidebar = input<boolean>(false);
  showSidebarState = false;


  @Output() closeSiderEvent = new EventEmitter<boolean>();
  @ContentChild('rightTopContent') rightTopContent: TemplateRef<any> | null =
    null;
  @ContentChildren(KanziTemplate) templates: QueryList<KanziTemplate> | null =
    null;
  rightTopContentTemplate: TemplateRef<any> | null = null;

  detailSidebarEffect = effect(() => {
    if (this.showSidebar()) {
      this.showSidebarState = true;
    } else {
      this.showSidebarState = false;
    }
  });

  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    (this.templates as QueryList<KanziTemplate>).forEach((item) => {
      switch (item.getType()) {
        case 'rightTopContent':
          this.rightTopContentTemplate = item.template;
          break;
        default:
          break;
      }
    });
  }

  closeDetailSider() {
    this.closeSiderEvent.emit(true);
  }

  ngOnDestroy(): void {
    this.detailSidebarEffect.destroy();
  }
}
